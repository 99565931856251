import styled, { css } from 'styled-components';

export const SvgCommonStyle = css`
  width: var(--unit-one-em);
  height: var(--unit-one-em);
`;

export const FlexAlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexAlignCenter = css`
  display: flex;
  align-items: center;
`;

export const HideScrollBar = css`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: white;
  }
`;
