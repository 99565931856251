import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';

const GENERATE_KEY_INFORMATION = gql`
  mutation generateBasicInformationForCopilotRoadmap($userId: String!, $region: String!) {
    generateBasicInformationForCopilotRoadmap(userId: $userId, region: $region)
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGeneratePlan = (): any => {
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const [generatePlan, { loading, error, data }] = useMutation<
    {
      res: boolean;
    },
    {
      userId: string;
      region: string;
    }
  >(GENERATE_KEY_INFORMATION, {
    client: crimsonAppApiClient,
  });
  const res = {
    generatePlan,
    loading,
    error,
    data: data?.res,
  };
  return res;
};
