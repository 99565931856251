import {
  Experience,
  ExtracurricularActivity,
  Honor,
  MissionObjectType,
  Reading,
  OtherSubcategories,
  StudentAcademic,
  StudentPortfolios,
  StudentPrograms,
  StudentResearch,
} from 'src/__generated__/graphqlTypes';

export enum Categories {
  ACADEMICS = 'ACADEMICS',
  SAT = 'SAT',
  ACTIVITIES = 'ACTIVITIES',
  COMPETITIONS_HONORS = 'COMPETITIONS_HONORS',
  PERSONAL_DEVELOPMENT = 'PERSONAL_DEVELOPMENT',
  CAREERS = 'CAREERS',
  TEST = 'TEST',
  APPLICATION_COMMON = 'APPLICATION_COMMON',
  APPLICATION_UNIVERSITY = 'APPLICATION_UNIVERSITY',
}
export enum Subcategories {
  School_Academic = 'School Academics',
  University_Programs = 'University Courses & Summer Programs',
  Research = 'Academic Research & Publications',
  Portfolios = 'Creative Portfolios',
  Internship_Experience = 'Internship & Work Experience',
  Extracurricular_Activities = 'Extracurricular Activities',
  Competitions_Honor = 'Competitions & Honors',
  Reading = 'Readings',
  Writing = 'Writings',
  Essential = 'Essential Skills & Experiences',
  Relationship = 'Relationship Building',
  Preparation = 'School Research & Preparation',
  Major = 'Major, Career and Interest Exploration',
  Networking = 'Networking',
}
export const MissionStatusEnum = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
  EXPIRED: 'Expired',
  POSTPONED: 'Postponed',
};
export type ActionItem = {
  id: string;
  description: string;
  finishedAt?: string;
  creatorId: string;
  createdAt: string;
  dueDate?: string;
  startAt?: string;
  status?: ActionItemStatus;
  type: string;
  mission?: MissionObjectType;
};
export enum ActionItemStatus {
  PLANNED = 'PLANNED',
  DONE = 'DONE',
}
export const MissionCategoryNameMapping = {
  ACADEMICS: 'Academics',
  ACTIVITIES: 'Activities',
  COMPETITIONS_HONORS: 'Honors',
  PERSONAL_DEVELOPMENT: 'Personal Development',
  CAREERS: 'Majors & Careers',
  TEST: 'Testing',
};
export const MissionCategoryMapping = {
  ACTIVITIES: {
    name: 'Activities',
    desc: 'The US Common App will give you up to 10 slots to showcase your extracurricular activities and it is usually recommended to reach that number by the time of application.',
    bgUrl: '/static/ecl_background.png',
    userUrl: '/static/ecl_user.png',
    color: '#2688BF',
  },
  COMPETITIONS_HONORS: {
    name: 'Honors',
    desc: 'The US Common App will give you up to 5 slots to showcase your competitions and honors. It is usually recommended to reach that number by the time of application.',
    bgUrl: '/static/honors_background.png',
    userUrl: '/static/honors_user.png',
    color: '#9D280E',
  },
};

export const MissionSubCategoryMapping = {
  'University Courses & Summer Programs': 'program',
  'Academic Research & Publications': 'research',
  'Creative Portfolios': 'project',
};
export type MissionDetailObjectType = {
  category?: string;
  description?: string;
  dueDate?: string;
  id: string;
  members?: string[];
  startAt?: string;
  status?: string;
  subcategory?: string;
  title?: string;
  userId: string;
  activityLogs: ActivityLogType[];
  integrationSource?: 'Ecl_SummerProgram' | 'Ecl_Competition' | string;
};

export type ActivityLogTemplate = {
  code: string;
  template: string;
};
export type ActivityLogType = {
  id: string;
  userId: string;
  exist: string;
  update: string;
  createdAt: string;
  template: ActivityLogTemplate;
  values: string;
};
export const missionStatusObject = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
  EXPIRED: 'Expired',
  POSTPONED: 'Postponed',
};

export type MissionItemType = {
  category?: string;
  description?: string;
  dueDate?: string;
  id?: string;
  members?: string[];
  startAt?: string;
  status?: string;
  subcategory?: string;
  title?: string;
  program?: StudentPrograms;
  research?: StudentResearch;
  schoolAcademic?: StudentAcademic;
  portfolios?: StudentPortfolios;
  actionItems?: ActionItem[];
  extracurricular?: ExtracurricularActivity;
  honor?: Honor;
  experience?: Experience;
  reading?: Reading;
  otherSubcategory?: OtherSubcategories;
};
export const SubCategoryMissionMapping: {
  [key: string]: {
    bgImg: string;
    color: string;
    titlePlaceholder?: string;
  };
} = {
  [Subcategories.University_Programs]: {
    bgImg: '/static/program.png',
    color: '#fff2ef',
    titlePlaceholder: 'Course or Program Name',
  },
  [Subcategories.Research]: {
    bgImg: '/static/research.png',
    color: 'rgba(108, 99, 255, 0.15)',
    titlePlaceholder: 'Research or Publication Name',
  },
  [Subcategories.Portfolios]: {
    bgImg: '/static/portfolios.png',
    color: 'rgba(59, 134, 254, 0.15)',
    titlePlaceholder: 'Portfolio Name',
  },
  [Subcategories.Extracurricular_Activities]: {
    bgImg: '/static/extracurricular.png',
    color: '#EDFDF4;',
  },
  [Subcategories.Competitions_Honor]: { bgImg: '/static/extracurricular.png', color: '#EDFDF4;' },
  [Subcategories.Internship_Experience]: { bgImg: '/static/experience.png', color: 'rgba(253, 170, 2, 0.15)' },
};

export type SelectOption = {
  label: string | number;
  value: string | number | boolean;
  key?: string;
};
