import { notification } from 'antd';
import { match } from 'ts-pattern';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import type { XProps } from '../types/global';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions }) => {
      return notification.error({
        message: match(extensions?.code)
          .with('UNAUTHORIZED', () => 'We are Sorry...')
          .otherwise(() => 'Error'),
        description: match(extensions?.code)
          .with(
            'UNAUTHORIZED',
            () => "The page you're trying to access has restricted access. Please refer to your system administrator. ",
          )
          .otherwise(() => 'Oops! Something went wrong at our end. Please try again later.'),
        icon: <div>&#128533;</div>,
      });
    });
  }

  if (networkError) {
    notification.error({
      message: match(networkError)
        .when(
          (e) => 'statusCode' in e && e.statusCode === 401,
          () => 'Error',
        )
        .when(
          (e) => 'statusCode' in e && e.statusCode === 403,
          () => 'We are Sorry...',
        )
        .otherwise(() => 'Problem Connecting'),
      description: match(networkError)
        .when(
          (e) => 'statusCode' in e && e.statusCode === 401,
          () => 'This account is not authenticated. Please refer to your system administrator. ',
        )
        .when(
          (e) => 'statusCode' in e && e.statusCode === 403,
          () => "The page you're trying to access has restricted access. Please refer to your system administrator. ",
        )
        .otherwise(() => 'Uh-oh! The network connection is lost. Please check your connectivity and try again.'),
      icon: <div>&#128533;</div>,
    });
    if ('statusCode' in networkError && networkError.statusCode === 403) {
      window.xprops?.authorize();
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: async (error) => {
      if ('statusCode' in error && error.statusCode === 403) {
        const result = await window.xprops?.authorize();
        return !result;
      }
      return false;
    },
  },
});

const getHttpLink = (endpoint: string, xprops?: XProps) => {
  const { featureSwitches, user, domain } = xprops ?? {};
  const mergedFlags = { ...featureSwitches };
  const { roleInfo, tenant } = user || {};
  const defaultHeaders: { [key: string]: string } = {
    'X-Feature-Switches': JSON.stringify(mergedFlags),
  };
  if (/(core-progress)|(core-student-center)|(localhost)|(app.crimson)/.test(endpoint)) {
    console.log({ user, roleInfo });
    defaultHeaders['x-user-id'] = user?.userId || '';
    defaultHeaders['x-user-role'] = roleInfo ? Buffer.from(roleInfo).toString('base64') : '';
    defaultHeaders['x-tenant-domain'] = domain ? new URL(domain).hostname : '';
    defaultHeaders['x-user-tenant'] = tenant ? `${tenant.name}:${tenant.level}` : '';
  }
  if (/localhost/.test(endpoint)) {
    defaultHeaders['x-user'] = JSON.stringify({
      userId: xprops?.userId ? xprops.userId : 'auth0-strategist',
      roles: xprops?.user?.role ? [{ id: xprops.user.role }] : [{ id: 'STRATEGIST' }, { id: 'STUDENT' }],
      tenant: tenant ? tenant.name : 'crimsonapp',
      level: tenant ? tenant.level : 3,
    });
  }
  return new HttpLink({
    uri: endpoint,
    headers: defaultHeaders,
  });
};

const authMiddleware = setContext(async () => {
  // get token from parent frame app.
  let token = await window.xprops?.getBearer();
  if (!token || token.length < 32) {
    await window.xprops?.authorize();
    token = await window.xprops?.getBearer();
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
});

export function getGraphQLClient(endpoint: string, xprops?: XProps): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([errorLink, authMiddleware, retryLink, getHttpLink(endpoint, xprops)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}
