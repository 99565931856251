import * as rt from 'runtypes';

export const Environment = rt.Union(rt.Literal('dev'), rt.Literal('staging'), rt.Literal('production'));
export type Environment = rt.Static<typeof Environment>;

export const AbsoluteUri = rt.String.withBrand('AbsoluteUri').withConstraint((str) => {
  try {
    new URL(str);
    return true;
  } catch {
    return `The string was not a valid Absolute Uri: ${str}`;
  }
});
export type AbsoluteUri = rt.Static<typeof AbsoluteUri>;

export const RelativeUri = rt.String.withBrand('RelativeUri').withConstraint((str) => {
  try {
    const TEST_RELATIVE_DOMAIN = 'http://_.com';
    const url = new URL(str, TEST_RELATIVE_DOMAIN);
    if (url.origin !== TEST_RELATIVE_DOMAIN) {
      `The string was an Absolute Uri, not a Relative Uri: ${str}`;
    }
    return true;
  } catch {
    return `The string was not a valid Relative Uri: ${str}`;
  }
});
export type RelativeUri = rt.Static<typeof RelativeUri>;
