import { ApolloQueryResult, OperationVariables } from '@apollo/client/core/types';
import { createContext, ReactElement, useState, useEffect, useRef } from 'react';
import { User, Role } from 'src/types/user';
import { AccessDenied } from 'src/ScComponents/AccessDenied';
import { Spin } from 'antd';

export type CurrentUserContextValue =
  | {
      userId: string;
      currentUser: User;
      studentInfo: User;
      refetchStudentInfo: (variables?: Partial<OperationVariables> | undefined) => Promise<
        ApolloQueryResult<{
          user: User;
        }>
      >;
      editPermitted: boolean;
      deletePermitted: boolean;
    }
  | Record<string, never>;

export const CurrentUserContext = createContext<CurrentUserContextValue>({});

export const defaultUserValue = {
  userId: '',
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  profileImageUrl: '',
  studentInfo: {
    schoolYearLevel: '',
    yearOfApplication: '',
  },
  roles: [],
  permissions: [],
  isTest: false,
  tenant: {
    name: 'crimsonapp',
    level: 3,
  },
};

export interface CurrentUserContextProviderProps {
  children: ReactElement | ReactElement[];
  value: CurrentUserContextValue;
}

const allowedUserRoles = [
  'STUDENT',
  'SUPER_ADMIN',
  'CASE_MANAGER',
  'OPERATIONAL_SUPPORT',
  'STRATEGIST',
  'HEAD_TUTOR',
  'TUTOR',
  'GUARDIAN',
  'ACADEMIC_ADVISOR',
];

export const CurrentUserContextProvider = ({ children, value }: CurrentUserContextProviderProps): JSX.Element => {
  const [pageAccessStatus, setPageAccessStatus] = useState<'Pending' | 'Authorized' | 'Denied'>('Pending');

  const rolesRef = useRef<Role[]>([]);
  useEffect(() => {
    rolesRef.current = value.currentUser?.roles ? value.currentUser?.roles : ([] as Role[]);
    if (!value.currentUser || value.currentUser?.roles.length === 0) {
      setPageAccessStatus('Pending');
      return;
    }
    const isAllowed = value.currentUser.roles?.some((role) => allowedUserRoles.includes(role.roleId));
    setPageAccessStatus(isAllowed ? 'Authorized' : 'Denied');
  }, [value.currentUser]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (rolesRef.current.length === 0) {
        setPageAccessStatus('Denied');
      }
    }, 10 * 1000);
    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  return (
    <CurrentUserContext.Provider value={value}>
      {pageAccessStatus === 'Pending' ? <Spin></Spin> : pageAccessStatus === 'Authorized' ? children : <AccessDenied />}
    </CurrentUserContext.Provider>
  );
};
