import React from 'react';
import { Routes, Route } from 'react-router-dom';
const ListViewPage = React.lazy(() => import('../pages/ListView'));
const GanntChartView = React.lazy(() => import('../pages/GanntChartView'));
const MindMapView = React.lazy(() => import('../pages/MindMapView'));

export type RouteType = {
  exact: boolean;
  path: string;
  id: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
};

export enum RoutePath {
  list = '/',
  timeline = '/timeline',
  mindmap = '/mindmap',
}

export const routes: RouteType[] = [
  {
    exact: true,
    path: RoutePath.list,
    id: 'list',
    component: ListViewPage,
  },
  {
    exact: true,
    path: RoutePath.timeline,
    id: 'timeline',
    component: GanntChartView,
  },
  {
    exact: true,
    path: RoutePath.mindmap,
    id: 'mindmap',
    component: MindMapView,
  },
];

const AppWrapperRoute: React.FC = () => {
  return (
    <Routes>
      {routes.map((route: RouteType) => (
        <Route key={route.path} path={route.path} element={<route.component data-test-id={route.id} />} />
      ))}
    </Routes>
  );
};

export default AppWrapperRoute;
