import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext } from 'react';

export const ApolloClientContext = createContext<
  | {
      roadMapAppApiClient: ApolloClient<NormalizedCacheObject>;
      crimsonAppApiClient: ApolloClient<NormalizedCacheObject>;
      studentCenterApiClient: ApolloClient<NormalizedCacheObject>;
    }
  | Record<string, never>
>({});
