import React, { useEffect } from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { StyledButtonFilled, StyledButtonOutlined } from 'src/components/CrButton';
import { BrowserRouter as Router, Link, useLocation, useNavigate } from 'react-router-dom';
import SpaceForTab from 'src/components/CrSpace';
import { useState } from 'react';
import { CopilotRoadmapPlanStatusType } from '@crimson-education/common-config';
import { ThemeContext, defaultVariables, VariablesType } from './context/ThemeContext';
import { GlobalStyle } from './theme';
import { ApolloClientContext } from './context/ApolloClientContext';
import AppWrapperRoute, { RoutePath } from './route/index';
import { firstCharUpperCase } from './utils';
import { ReactComponent as ViewTimeline } from '@material-design-icons/svg/outlined/view_timeline.svg';
import { ReactComponent as ListView } from '@material-design-icons/svg/outlined/format_list_bulleted.svg';
import { ReactComponent as MindView } from '@material-design-icons/svg/outlined/hub.svg';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FlexAlignCenterDiv, SvgCommonStyle } from './components/CommonStyle';
import { getGraphQLClient } from './graphql';
import { defaultUserValue, CurrentUserContextProvider } from './context/CurrentUserContext';
import { LoginUserContext } from './context/LoginUserContext';
import { User } from './types/user';
import { useQuery, ApolloProvider } from '@apollo/client';
import { GET_ROADMAP_ID, QUERY_USER_INFO } from './graphql/User';
import { getConfig } from './config';
import * as zoid from 'zoid/dist/zoid.frameworks';
import './App.less';
import { RoadMapContext } from './context/RoadMapContext';
import * as Logger from '@crimson-education/browser-logger';
import { getPermissions, usePermissionCheck } from './utils/permission';
import { PermissionAction, PermissionResourceType } from '@crimson-education/common-config/lib/authorization';
import { FeatureSwitchEnhancer, useFeatureFlag } from 'src/featureSwitches';
import { useIdentifyUser, useFeatureSwitches } from 'src/featureSwitches';
import { SharedUiLibConfig } from './utils/sharedUiLibConfig';
import { Button, Dropdown, MenuProps, notification, Tooltip } from 'antd';
import { EntryPointCheckType } from './types/global';
import { useGeneratePlan } from './Hooks/userGenerateBasicInfoForCopilotRoadmap';

TimeAgo.addDefaultLocale(en);
const config = getConfig(window.process.env);
zoid.create({
  // The html tag used to render my component
  tag: 'roadmap-component',
  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: config.domain,

  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    featureSwitches: {
      type: 'object',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    studentId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: true,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});

const ViewIconStyle = css`
  ${SvgCommonStyle}
  font-size: 1.5em;
  fill: var(--color-indigo);
  margin-right: 8.5px;
`;

const ViewTimelineIcon = styled(ViewTimeline)`
  ${ViewIconStyle}
`;

const ListViewIcon = styled(ListView)`
  ${ViewIconStyle}
`;

const MindMapViewIcon = styled(MindView)`
  ${ViewIconStyle}
  font-size: 1.2em;
`;

const NoStyledLink = styled(Link)`
  &:focus-visible {
    outline-offset: 0px;
  }
  &:-webkit-any-link:active {
    color: inherit;
  }
  &:-webkit-any-link:active {
    text-decoration: none;
  }
`;

const RootContainer = styled.div`
  display: flex;
  /* width: 100vw; */
  flex-direction: column;
  justify-content: center;
  background: var(--color-mist);
  min-height: 100vh;
`;

const ButtonGroups = styled(FlexAlignCenterDiv)`
  padding-left: var(--padding-page-content-left);
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

const buttonFocusedStyle = css`
  background: var(--color-indigo);
  color: white;
  svg {
    fill: white;
  }
`;

const LinkButton = styled(StyledButtonOutlined)`
  border: 2px solid #e3e7ed;
  font-size: 14px;
  background: white;
  margin-right: 8px;
  height: 32px;
  &:hover svg {
    fill: white;
  }
  &:focus {
    border: 2px solid #e3e7ed;
  }
  &.focus {
    ${buttonFocusedStyle}
  }
`;

const RoadmapPdfButton = styled(StyledButtonFilled)`
  font-size: 14px;
  height: 38px;
`;

const EditButton = styled(Button)`
  font-size: 12px;
  border: none;
  color: #73747d;
  font-weight: 500;
  background: transparent;
  &:hover {
    background: transparent;
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex: 1 auto;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
`;

const roadMapAppApiClient = getGraphQLClient(
  new URL('/roadmap-service/graphql', config.roadMapAPIUrl).href,
  window.xprops,
);
const crimsonAppApiClient = getGraphQLClient(new URL('/graphql', config.crimsonAppAPIUrl).href, window.xprops);
const studentCenterApiClient = getGraphQLClient(new URL('/graphql', config.studentCenterAPIUrl).href, window.xprops);
const pathfinderApiClient = getGraphQLClient(new URL('/graphql', config.pathfinderAPIUrl).href, window.xprops);
const ALL_VIEWS = {
  list: {
    link: RoutePath.list,
    icon: () => <ListViewIcon />,
  },
  timeline: {
    link: RoutePath.timeline,
    icon: () => <ViewTimelineIcon />,
  },
  mindmap: {
    link: RoutePath.mindmap,
    icon: () => <MindMapViewIcon />,
  },
};

const NavButtons = (): JSX.Element => {
  const location = useLocation();

  return (
    <ButtonGroups>
      {(Object.keys(ALL_VIEWS) as Array<keyof typeof ALL_VIEWS>).map((key) => (
        <NoStyledLink to={ALL_VIEWS[key].link + window.location.search} key={key}>
          <LinkButton className={location.pathname === ALL_VIEWS[key].link ? 'focus' : ''}>
            {ALL_VIEWS[key].icon()}
            {`${firstCharUpperCase(key)}`} View
          </LinkButton>
        </NoStyledLink>
      ))}
    </ButtonGroups>
  );
};

const PlanToolTip = (): JSX.Element => {
  return (
    <Tooltip
      title={
        <>
          <div style={{ fontWeight: 700, fontSize: '14px' }}>What is a Roadmap PDF?</div>
          <div style={{ fontWeight: 500, fontSize: '14px' }}>
            The Roadmap PDF is a client-facing initial strategy plan. It is created by Copilot AI using notes from your
            ISM/RMM conversation with the student and the missions you suggested in the Roadmap.
          </div>
        </>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
const RoadmapPdfButtonComponent = ({ entryPoints }: { entryPoints: EntryPointCheckType }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { generatePlan } = useGeneratePlan();
  const studentId = window?.xprops?.studentId;
  const divisions = entryPoints.divisions;
  const planInitStatus = entryPoints.planInitStatus;
  const meetingStatus = entryPoints.meetingStatus;
  const genRoadmapForRegion = async (region: string) => {
    const status = region === 'UK' ? planInitStatus.ukStatus : planInitStatus.usStatus;
    const meeting = region === 'UK' ? meetingStatus?.UK : meetingStatus?.US;
    if (!meeting) {
      notification.error({
        message: 'ISM/RMM Completion Required',
        description:
          'The Roadmap PDF can only be generated after your Initial Strategy Meeting or Roadmap Meeting with the student is completed. Please ensure at least one meeting is finished before proceeding, as session notes are needed for AI to create the plan.',
      });
      return;
    } else {
      switch (status) {
        case CopilotRoadmapPlanStatusType.FirstStageCompleted:
          window.xprops?.generateRoadmapPlan(true, region);
          break;
        case null:
        case CopilotRoadmapPlanStatusType.FirstStageInProgress:
        case CopilotRoadmapPlanStatusType.FirstStageFailed: {
          setLoading(true);
          const success = await generatePlan({ variables: { userId: studentId, region } });
          if (success) {
            window.xprops?.generateRoadmapPlan(true, region);
          } else {
            notification.error({
              message: 'Generate Failed',
              description: 'Please try again later',
            });
          }
          setLoading(false);
          break;
        }
        case CopilotRoadmapPlanStatusType.SecondStageInProgress:
        case CopilotRoadmapPlanStatusType.SecondStageInFailed:
        case CopilotRoadmapPlanStatusType.InProgress:
        case CopilotRoadmapPlanStatusType.Done: {
          const roadmapId = region === 'UK' ? planInitStatus.ukId : planInitStatus.usId;
          roadmapId && window?.xprops?.history.push('/copilot/roadmap/' + roadmapId);
          break;
        }
        default: {
          notification.warn({
            message: 'Unexpected status: ' + status,
            description: 'Please try again later',
          });
          break;
        }
      }
    }
  };

  const items: MenuProps['items'] = [];
  const createMenuItem = (region: 'US' | 'UK', status: string | null) => ({
    key: region,
    label: (
      <EditButton onClick={() => genRoadmapForRegion(region)} disabled={loading}>
        {status ? 'Continue' : 'Generate'} roadmap for {region}
      </EditButton>
    ),
  });
  items.push(createMenuItem('US', planInitStatus.usStatus));
  items.push(createMenuItem('UK', planInitStatus.ukStatus));

  if (divisions.length === 0 || (divisions.includes('US') && divisions.includes('UK'))) {
    return (
      <Dropdown menu={{ items }} placement="bottom">
        <RoadmapPdfButton>
          Generate Roadmap PDF <PlanToolTip />
        </RoadmapPdfButton>
      </Dropdown>
    );
  }
  const region = divisions.includes('US') ? 'US' : 'UK';
  return (
    <RoadmapPdfButton onClick={() => genRoadmapForRegion(region)} disabled={loading}>
      {planInitStatus.usStatus || planInitStatus.ukStatus ? 'Continue ' : 'Generate'} Roadmap PDF <PlanToolTip />
    </RoadmapPdfButton>
  );
};
const Pages = (): JSX.Element => {
  const location = useLocation();
  const COPILOT_ROADMAP = useFeatureFlag('COPILOT_ROADMAP');
  useEffect(() => {
    const url = location.pathname + location.search;
    window?.xprops?.onMessage?.({ event: 'route', data: url });
    // eslint-disable-next-line
  }, [location, window?.xprops?.onMessage]);
  const navigate = useNavigate();
  const registerEventEmitter = window.xprops?.registerEventEmitter;
  const unregisterEventEmitter = window.xprops?.unregisterEventEmitter;
  const role = window.xprops?.user?.role;
  const userRoles = window.xprops?.user?.userRoles;
  const isSa = role === 'SUPER_ADMIN' || userRoles?.includes('SUPER_ADMIN');
  const isStrategist = role === 'STRATEGIST' || userRoles?.includes('STRATEGIST');
  const entryPointCheck = window.xprops?.entryPointCheck;
  const canViewEntryPoints =
    COPILOT_ROADMAP && (entryPointCheck?.entrypoints.US || entryPointCheck?.entrypoints.UK) && (isStrategist || isSa);
  useEffect(() => {
    if (window != window.top && registerEventEmitter) {
      registerEventEmitter('refresh', (url: string) => {
        // listen to the parent component refresh event,
        // then replace the url into inner url
        // replace users/{userId}/student-center to ''
        const regex = /\/users.*roadmap/i;
        navigate(url.replace(regex, '') || '/');
      });
    }
    return () => {
      unregisterEventEmitter?.('refresh');
    };
  });
  return (
    <>
      <div style={{ display: 'flex', width: '100%', paddingRight: '64px', justifyContent: 'space-between' }}>
        <NavButtons />{' '}
        {canViewEntryPoints && entryPointCheck && <RoadmapPdfButtonComponent entryPoints={entryPointCheck} />}
      </div>
      <AppWrapperRoute />
    </>
  );
};

const App = (): JSX.Element => {
  const studentId = window?.xprops?.studentId;
  const [variables, setVariables] = useState<VariablesType>(defaultVariables);
  const [currentUser, setCurrentUser] = useState<User>(defaultUserValue);
  const [studentInfo, setStudentInfo] = useState<User>(defaultUserValue);
  const currentUserId = window?.xprops?.userId;
  const [roadMapId, setRoadMapId] = useState('');
  const { data: currentUserData, loading: currentUserDataLoading } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    variables: {
      userId: currentUserId,
    },
    client: crimsonAppApiClient,
    skip: !currentUserId,
  });
  const {
    data: studentInfoData,
    loading: studentInfoInfoloading,
    refetch: refetchStudentInfo,
  } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    variables: {
      userId: studentId,
    },
    client: crimsonAppApiClient,
    skip: !studentId,
  });
  const { data: roadMapData, loading: roadMapDataLoading } = useQuery(GET_ROADMAP_ID, {
    variables: {
      studentId: studentId,
    },
    skip: !studentId,
    client: roadMapAppApiClient,
  });
  const identifyUser = useIdentifyUser();

  const ALL_FEATURE_FLAGS = useFeatureSwitches();
  const loginUserId = currentUser.userId;
  useEffect(() => {
    if (loginUserId) {
      identifyUser(loginUserId);
    }
  }, [identifyUser, loginUserId]);
  const permissions = currentUser.permissions;
  const permissionEdit = {
    action: PermissionAction.Edit,
    resourceType: PermissionResourceType.MissionControl,
  };
  const permissionDelete = {
    action: PermissionAction.Delete,
    resourceType: PermissionResourceType.MissionControl,
  };
  const editAble = usePermissionCheck(permissionEdit, [studentId], ['STUDENT'], loginUserId, permissions);
  const deleteAble = usePermissionCheck(permissionDelete, [studentId], ['STUDENT'], loginUserId, permissions);

  const isAcademicAdvisor = currentUser.roles.some((role) => {
    return role.roleId === 'ACADEMIC_ADVISOR';
  });

  useEffect(() => {
    if (!roadMapDataLoading && roadMapData?.roadmap) setRoadMapId(roadMapData?.roadmap?.id);
  }, [roadMapDataLoading, roadMapData]);

  useEffect(() => {
    async function load() {
      if (!currentUserDataLoading && currentUserData?.user) {
        const permissions = await getPermissions(currentUserData.user.userId);
        const currentUser = { ...currentUserData?.user, permissions };
        setCurrentUser(currentUser);
        const contextMetadata = {
          isAnonymous: 'false',
          userId: currentUser.userId,
        };
        Logger.addMetadata(contextMetadata);
        Logger.setUser({
          id: currentUser.userId,
          email: currentUser.email,
          username: currentUser.email,
          name: `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}`.trim(),
        });
        Logger.trackEvent({ message: 'roadmap-app-ready' });
        Logger.recordSession();
      }
    }
    load();

    if (!studentInfoInfoloading && studentInfoData?.user) {
      setStudentInfo(studentInfoData?.user);
    }
  }, [currentUserDataLoading, currentUserData, studentInfoData, studentInfoInfoloading, currentUserId]);
  return (
    <React.Suspense fallback={null}>
      <GlobalStyle />
      {studentId && currentUserId ? (
        <ThemeContext.Provider value={{ variables, setVariables }}>
          <ThemeProvider theme={variables}>
            <ApolloClientContext.Provider
              value={{
                roadMapAppApiClient,
                crimsonAppApiClient,
                studentCenterApiClient,
              }}
            >
              <ApolloProvider client={roadMapAppApiClient}>
                <RoadMapContext.Provider value={{ roadMapId }}>
                  <LoginUserContext.Provider value={window.xprops?.user || {}}>
                    <CurrentUserContextProvider
                      value={{
                        currentUser,
                        userId: currentUserId,
                        studentInfo,
                        refetchStudentInfo,
                        editPermitted: isAcademicAdvisor && studentInfoData?.user.isTest ? true : editAble.isPermitted,
                        deletePermitted:
                          isAcademicAdvisor && studentInfoData?.user.isTest ? true : deleteAble.isPermitted,
                      }}
                    >
                      <SharedUiLibConfig
                        featureFlags={{
                          featureFlags: ALL_FEATURE_FLAGS as Record<string, boolean>,
                        }}
                        userInfo={{
                          userId: currentUser.userId,
                          email: currentUser.email,
                          firstName: currentUser.firstName,
                          lastName: currentUser.lastName,
                          profileImageUrl: currentUser.profileImageUrl,
                          roles: currentUser.roles,
                          fullName: currentUser.fullName,
                        }}
                        apiClients={{
                          coreApiClient: crimsonAppApiClient,
                          studentCenterApiClient,
                          roadmapApiClient: roadMapAppApiClient,
                          pathfinderApiClient,
                        }}
                      >
                        <RootContainer>
                          <SpaceForTab />
                          <AppContainer>
                            <Router>
                              <Pages />
                            </Router>
                          </AppContainer>
                        </RootContainer>
                      </SharedUiLibConfig>
                    </CurrentUserContextProvider>
                  </LoginUserContext.Provider>
                </RoadMapContext.Provider>
              </ApolloProvider>
            </ApolloClientContext.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>
      ) : (
        <div>Please input the correct user id</div>
      )}
    </React.Suspense>
  );
};

type TypeEnhancer = <P extends JSX.IntrinsicAttributes>(Comp: React.ComponentType<P>) => React.ComponentType<P>;
export default (FeatureSwitchEnhancer as TypeEnhancer)(App);
