import React, { useMemo } from 'react';
import {
  ApiClientProvider,
  ApiClientContextType,
  UserInfoContextType,
  FeatureFlagContextType,
  UserInfoProvider,
  FeatureFlagProvider,
} from '@crimson-education/core-shared-ui';
import '@crimson-education/core-shared-ui/index.css';

type SharedUiLibConfigProps = {
  children: React.ReactNode;
  apiClients: ApiClientContextType;
  userInfo: UserInfoContextType;
  featureFlags: FeatureFlagContextType;
};
export const SharedUiLibConfig = ({
  children,
  apiClients,
  userInfo,
  featureFlags,
}: SharedUiLibConfigProps): JSX.Element => {
  const apiClientContextValue = useMemo(() => apiClients, [apiClients]);
  const userInfoContextValue = useMemo(() => userInfo, [userInfo]);
  const featureFlagContextValue = useMemo(() => featureFlags, [featureFlags]);
  return (
    <ApiClientProvider value={apiClientContextValue}>
      <UserInfoProvider value={userInfoContextValue}>
        <FeatureFlagProvider value={featureFlagContextValue}>{children}</FeatureFlagProvider>
      </UserInfoProvider>
    </ApiClientProvider>
  );
};
