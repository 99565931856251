import {
  constructFeatureSwitches,
  constructLDFeatureSwitch,
  transformAndFilterFeatureSwitches,
} from '@crimson-education/feature-switches-web';
import { isProduction, isStaging, isDev, launchDarkly } from '../utils/settings';

import productionFeatures from './features.production.json';
import stagingFeatures from './features.staging.json';
import devFeatures from './features.development.json';

const { featureSwitches, getFeatureSwitchesAsString, FeatureToggle } = constructFeatureSwitches(
  {
    dev: devFeatures,
    staging: stagingFeatures,
    production: productionFeatures,
  },
  {
    isDev,
    isStaging,
    isProduction,
  },
);

export { featureSwitches, getFeatureSwitchesAsString, FeatureToggle };

const { useFeatureFlag, enhancer, useFeatureSwitches, useIdentifyUser, FeatureSwitchContext } =
  constructLDFeatureSwitch(launchDarkly.clientSideId, transformAndFilterFeatureSwitches(featureSwitches), {
    hotReload: true,
    waitForReady: isDev(),
    showLog: isDev(),
    proxy: {
      enable: true,
    },
  });

export { useFeatureFlag, enhancer as FeatureSwitchEnhancer, useFeatureSwitches, useIdentifyUser, FeatureSwitchContext };
