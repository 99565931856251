import { gql } from '@apollo/client';

export const USER_INFO_FRAGMENT = gql`
  fragment userInfo on userType {
    userId
    fullName
    firstName
    lastName
    email
    profileImageUrl
    country
    studentInfo {
      schoolYearLevel
      yearOfApplication
    }
    tenant {
      name
      id
      domain
      level
    }
    roles {
      roleId
      isPrimary
    }
    isTest
  }
`;

export const UPDATE_YEAR_OF_APPLICATION = gql`
  mutation updateYearOfApplication($userId: String!, $yearOfApplication: String!) {
    updateYearOfApplication(userId: $userId, yearOfApplication: $yearOfApplication)
  }
`;

export const GET_ROADMAP_ID = gql`
  query getRoadMapId($studentId: String!) {
    roadmap(studentId: $studentId) {
      id
    }
  }
`;

export const QUERY_USER_INFO = gql`
  query userById($userId: String!) {
    user: user(userId: $userId) {
      ...userInfo
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const ListUserType = gql`
  fragment detailedUserType on userType {
    userId
    firstName
    lastName
    country
    fullName
    profileImageUrl
    roles {
      isPrimary
      roleId
      role {
        name
      }
    }
  }
`;

export const GET_USERS_BY_IDS = gql`
  ${ListUserType}
  query fetchUsersByIds($userIds: [String]!, $studentId: String) {
    usersBasicInfo(userIds: $userIds) {
      ...detailedUserType
      tutorInfo {
        contractSubjects(studentId: $studentId) {
          name
        }
      }
    }
  }
`;

export const GET_TEAM_MEMBERS_BY_STUDENT = gql`
  ${ListUserType}
  query getTeamMembersByStudent($userId: String!) {
    getTeamMembersByStudent(userId: $userId) {
      ...detailedUserType
      tutorInfo {
        contractSubjects(studentId: $userId) {
          name
        }
      }
    }
  }
`;
